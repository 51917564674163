import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Menu, Layout, Input, Button, Tooltip, Modal, Dropdown, Row, Col } from "antd";
import { SaveOutlined, MenuFoldOutlined, MenuUnfoldOutlined, SelectOutlined, PhoneOutlined, CloseSquareOutlined, PauseCircleOutlined, CoffeeOutlined, AudioMutedOutlined, RetweetOutlined, AppstoreOutlined, ApiOutlined } from '@ant-design/icons';
import Logo from './Logo';
import NavPanel from './NavPanel';
import NavSearch from './NavSearch';
import NavProfile from './NavProfile';
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import StatusModal from 'components/custom-components/StatusModal';
import utils from 'utils'
import { DisplayTimer } from "components/util-components/Timer/index";
import CounterContext from "contexts/CounterContext";
import baseService from "services/BaseService";
import { RightOutlined, DoubleRightOutlined } from "@ant-design/icons";
import IntlMessage from "../util-components/IntlMessage";
import Utils from "utils";
import { ENDPOINT } from "configs/AppConfig";
import socketIOClient from "socket.io-client";
import * as workerTimers from 'worker-timers';
import ls from 'localstorage-slim';

const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const { Header } = Layout;

export const HeaderNav = props => {
    const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme } = props;
    const [searchActive, setSearchActive] = useState(false)
    const [durumVisible, setdurumVisible] = useState(false)
    const [recordStatus, setrecordStatus] = useState(false)
    const [visible, setVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [transferInputValue, setTransferInputValue] = useState('');
    const [breakVisible, setBreakVisible] = useState(false);
    const [outboundList, setOutboundList] = useState([])
    const [transferCallData, setTransferCallData] = useState(null)
    // const [setTransferCallData] = useState(null)
    const { langs } = useContext(CounterContext)

    const [degistir, setdegistir] = useState(false);
    const { time } = useContext(CounterContext)
    const { setTime } = useContext(CounterContext)
    const { status } = useContext(CounterContext)
    const { setstatus } = useContext(CounterContext)
    const { logo } = useContext(CounterContext)
    const { setlogo } = useContext(CounterContext)
    const { setlogomargin } = useContext(CounterContext)
    const { molaadet } = useContext(CounterContext)
    const { molasure } = useContext(CounterContext)
    const { setmolaadet } = useContext(CounterContext)
    const { setmolasure } = useContext(CounterContext)

    const { mola } = useContext(CounterContext)
    const { setmoladisabled } = useContext(CounterContext)
    const { setmola } = useContext(CounterContext)

    const id = ls.get("id", { decrypt: true })
    let users = ls.get("users", { decrypt: true });
    const onSearchClose = () => {
        setSearchActive(false)
    }
    function success(message) {
        Modal.success({
            content: message,
        });
    }

    function error(message) {
        Modal.error({
            content: message,
        });
    }

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);
        socket.on('dialbegin', async function (data) {
            if (data) {
                const { line, calleridname, number, incoming } = data;
                if (incoming) {
                    let user = await baseService.getPost("api/extension/user/" + line);
                    let img = `${process.env.PUBLIC_URL}/img/setcall-logo-notification.jpg`;
                    if (+user.ID === +id) {
                        const notification = new Notification('GELEN ARAMA (CEVAPLA)', {
                            body: `${calleridname} \n${number}`,
                            icon: img,
                            requireInteraction: true
                        })
                        notification.onclick = () => {
                            const answerModal = document.querySelector('#answerContainer');
                            if (answerModal?.style.display === "block") {
                                window.AnswerAudioCall(1, langs);
                            }
                            window.focus();
                        }
                        workerTimers.setTimeout(function () { notification.close() }, 15000);

                        const transferContainer = document.querySelector('#transfer-data');
                        setTransferCallData(prev => {
                            if (prev) {
                                if (langs === "en") {
                                    transferContainer.innerText = "Transferred from: "
                                }
                                if (langs === "de") {
                                    transferContainer.innerText = "Transferiert von: "
                                }
                                if (langs === "tr") {
                                    transferContainer.innerText = "Transfer eden: "
                                }
                                transferContainer.innerText += prev?.from;
                            }
                            return null
                        })
                    }
                }
            }
        })

        socket.on('transferCall', async function (data) {
            const { to } = data;
            if (to === users) {
                setTransferCallData(data)
            }
        })

        return () => socket.close();
    }, [])

    useEffect(() => {
        if (!("Notification" in window)) {
            console.log("Tarayıcı bildirimleri desteklemiyor.");
        } else if (Notification.permission === "denied") {
            console.log("Bildirim izni kullanıcı tarafından reddedildi.");
        } else if (Notification.permission === "default") {
            Notification.requestPermission(function (permission) {
                if (permission === "granted") {
                    console.log("Bildirim izni kullanıcı tarafından kabul edildi.");
                } else if (permission === "denied") {
                    console.log("Bildirim izni kullanıcı tarafından reddedildi.");
                }
            });
        }
    }, [])

    useEffect(() => {
        let webphone = ls.get("webphone", { decrypt: true });
        if (+webphone !== 1) {
            document.getElementById("header-row").remove();
            document.getElementById("callNumber").remove();
            document.getElementById("callTimer").remove();
            document.getElementById("callStatus").remove();
        }
    }, [])

    useEffect(() => {
        (async function () {
            // await idleDetection();

            let response = await baseService.getPost("api/user/" + id)
            if (response?.Status === "Aktif") {
                setstatus(false)
                let data = await baseService.getPost("api/userbreak/" + id);
                let breaks = await baseService.getPost("api/break");

                let breakReportStatus;
                breaks.forEach(breakItem => {
                    breakReportStatus = {
                        ...breakReportStatus,
                        [breakItem.Name.trim().toLowerCase()]: breakItem.NotReport,
                    }
                });

                let toplam = 0;
                let totalBreakCount = 0;
                if (data) {
                    data?.forEach(element => {
                        if (!breakReportStatus?.[element?.StatusName?.trim().toLowerCase()]) {
                            if (element?.sure > 0) {
                                toplam = element?.sure + toplam;
                                totalBreakCount++
                            }
                        }
                    });
                    // Hours, minutes and seconds
                    var hrs = ~~(toplam / 3600);
                    var mins = ~~((toplam % 3600) / 60);
                    var secs = ~~toplam % 60;

                    // Output like "1:01" or "4:03:59" or "123:03:59"
                    const ret = Utils.timeToStringConverter(hrs, mins, secs)

                    setmolaadet(":" + totalBreakCount)
                    setmolasure(":" + ret)

                }
            }
            else {
                setstatus(true)
                setmola(response?.Status)
                let data2 = await baseService.getPost("api/getagent/" + id);
                let t = new Date();
                t.setSeconds(t.getSeconds() + data2)
                t = new Date(t).getTime();
                setTime(t)
            }
        })();
    }, [id, setTime, setmola, setmolaadet, setmolasure, setstatus]);

    useEffect(() => {
        document.getElementsByClassName("transferModal")[0].parentElement.style.display = "none";
    }, [])

    useEffect(() => {
        (async function () {
            let response = await baseService.getPost("api/blacklistoutbound/phone")
            response?.length > 0 && setOutboundList(response)
        })();
    }, [])

    useEffect(() => {
        (async function () {
            let response = await baseService.getPost("api/user/queues_config/" + id)
            if (response.length > 0) {
                setBreakVisible(true)
            }
        })();
    }, [id])

    const onToggle = () => {
        if (!isMobile) {
            if (logo === "43%") {
                setlogo("150%")
                setlogomargin("0%")
            }
            if (logo === "150%") {
                setlogo("43%")
                setlogomargin("30%")

            }
            toggleCollapsedNav(!navCollapsed)
        } else {
            onMobileNavToggle(!mobileNav)
        }
    }
    const showdurumModal = () => {

        setdegistir(false)
        setdurumVisible(true)

        const action = 'Mola başlatıldı.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    };
    const showdurumdegistirModal = () => {
        setmoladisabled(true)
        setTime(0)

        setdegistir(true)
        setdurumVisible(true)

        const action = 'Mola değiştirildi.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    };

    const isNavTop = navType === NAV_TYPE_TOP ? true : false
    const mode = () => {
        if (!headerNavColor) {
            return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
        }
        return utils.getColorContrast(headerNavColor)
    }

    function ara() {
        let phoneValue = document.getElementById("phone").value;
        let blockStatus = ls.get('gidenaramaengelle', { decrypt: true });
        let blocked = false;
        if (phoneValue) {
            if (blockStatus) {
                if (+blockStatus === 1) {


                    if (phoneValue?.split("")?.length > 9) {
                        let blockedList = outboundList?.filter(x => x?.Phone?.slice(-10) === phoneValue?.slice(-10))?.length;
                        if (blockedList > 0) {
                            blocked = true;
                            alert("Bu numarayı arayamazsınız!");
                        }
                    }
                }
            }

            // if (phoneValue?.split("")?.length === 11) {
            const statuContainer = document.getElementById(`callStatus`).innerHTML;
            if (!blocked) {
                if (!statuContainer) {
                    window.DialByLine(document.getElementById("phone").value, langs)
                    setInputValue("")
                }
            }
        }


        const action = 'Telefon araması yapıldı.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    }

    function endCall() {
        // if(recordStatus){
        //     window.StopRecording(1);
        //     setrecordStatus(false);
        // }
        if (recordStatus) document.getElementsByName("stopRecord-btn")[0].click();

        const statuContainer = document.getElementById(`callNumber`)?.innerHTML;
        if (statuContainer) {
            window.endSession(1, langs)
            setInputValue("");
        }

        const action = 'Telefon araması kapatıldı.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    }

    function startRe(){
        const statuContainer = document.getElementById(`callStatus`).innerHTML;
        if (statuContainer) {
            window.StartRecording(1)
            setrecordStatus(true);
        }

        const action = 'Telefon ses kaydı başlatıldı.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    }

    function stopRe(){
        window.StopRecording(1);
        setrecordStatus(false);

        const action = 'Telefon ses kaydı durdurldu.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    }
    function cancelCall() {
        window.cancelSession(1, langs)

        const action = 'Telefon araması iptal edildi.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    }

    const navMode = mode()
    const getNavWidth = () => {
        if (isNavTop || isMobile) {
            return '0px'
        }
        if (navCollapsed) {
            return `${SIDE_NAV_COLLAPSED_WIDTH}px`
        } else {
            return `${SIDE_NAV_WIDTH}px`
        }
    }

    const holdSession = () => {
        const statuContainer = document.getElementById(`callStatus`).innerHTML;
        if (statuContainer) {
            window.holdSession(1, langs)
        }

        const action = 'Telefon araması bekletildi.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    }

    const unholdSession = () => {
        const statuContainer = document.getElementById(`callStatus`).innerHTML;
        if (statuContainer) {
            window.unholdSession(1, langs)
        }

        const action = 'Telefon araması devam edildi.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    }

    const muteSession = () => {
        const statuContainer = document.getElementById(`callStatus`).innerHTML;
        if (statuContainer) {
            window.MuteSession(1, langs)
        }

        const action = 'Telefon aramasında mikrafon kapatıldı.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    }
    const unmuteSession = () => {
        const statuContainer = document.getElementById(`callStatus`).innerHTML;
        if (statuContainer) {
            window.UnmuteSession(1, langs)
        }

        const action = 'Telefon aramasında mikrafon açıldı.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    }

    function transferCall() {
        const statuContainer = document.getElementById(`callStatus`).innerHTML;
        if (statuContainer) {
            setTransferInputValue("")
            window.holdSession(1, langs)

            document.getElementById("callTransferContainer").style.display = "block"
            document.getElementById("transferAttentedCalling").style.display = "none"
            document.getElementsByClassName("transferModal")[0].parentElement.style.display = "block";
        }

        const action = 'Telefon araması transfer edildi.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    }

    const handleCancel = () => {
        const statuContainer = document.getElementById(`callStatus`).innerHTML;
        if (statuContainer) {
            window.unholdSession(1, langs)
            document.getElementsByClassName("transferModal")[0].parentElement.style.display = "none";
        }
    }

    const blindTransfer = () => {
        let phoneNumber = document.getElementById("transferNoInput").value;
        if (phoneNumber) {
            window.BlindTransfer(1, langs)
        } else {
            document.getElementsByClassName("transferNoInputError")[0].style.display = "block"
            workerTimers.setTimeout(() => {
                document.getElementsByClassName("transferNoInputError")[0].style.display = "none"
            }, 2000);
        }
    }

    const attentedTransfer = () => {
        let phoneNumber = document.getElementById("transferNoInput").value;
        if (phoneNumber) {
            window.AttendedTransfer(1, langs)
        } else {
            document.getElementsByClassName("transferNoInputError")[0].style.display = "block"
            workerTimers.setTimeout(() => {
                document.getElementsByClassName("transferNoInputError")[0].style.display = "none"
            }, 2000);
        }
    }


    const showAktif = () => {
        (async function () {

            let response = await baseService.setPost("api/userbreak/finish/" + id)
            if (response === "Başarılı") {
                success(document.getElementById(`MolaBitmistir`).innerHTML)
                let response2 = await baseService.setPost("api/userbreak/update/" + id)
                if (response2 === "Başarılı") {
                    let response = await baseService.setPost("api/queue/pause/" + id + "/false")
                    if (response === "Başarılı") {
                        setstatus(false)
                        let t = new Date();
                        t.setMinutes(t.getMinutes() + 40)
                        t = new Date(t).getTime();
                        setTime(0)
                        setmoladisabled(true)
                        let data = await baseService.getPost("api/userbreak/" + id);
                        let breaks = await baseService.getPost("api/break");

                        let breakReportStatus;
                        breaks.forEach(breakItem => {
                            breakReportStatus = {
                                ...breakReportStatus,
                                [breakItem.Name.trim().toLowerCase()]: breakItem.NotReport,
                            }
                        });

                        let toplam = 0;
                        let totalBreakCount = 0;
                        if (data) {
                            data?.forEach(element => {
                                if (!breakReportStatus?.[element?.StatusName?.trim().toLowerCase()]) {
                                    if (element?.sure > 0) {
                                        toplam = element?.sure + toplam;
                                        totalBreakCount++
                                    }
                                }
                            });
                            var hrs = ~~(toplam / 3600);
                            var mins = ~~((toplam % 3600) / 60);
                            var secs = ~~toplam % 60;

                            // Output like "1:01" or "4:03:59" or "123:03:59"
                            const ret = Utils.timeToStringConverter(hrs, mins, secs)

                            setmolaadet(":" + totalBreakCount)
                            setmolasure(":" + ret)

                        }
                    }

                } else {
                    error(document.getElementById(`Birhataolustu`).innerHTML)
                }

            } else if (response === "Bir hata oluştu.") {
                error(document.getElementById(`Birhataolustu`).innerHTML)

            }
            else if (response === "Böyle bir kayıt mevcut değil.") {
                error(document.getElementById(`Boylebirkayitmevcutdegil`).innerHTML)

            }
            else if (response === "Eksik bilgiler var.") {
                error(document.getElementById(`Eksikbilgilervar`).innerHTML)

            }
            else {
                error(document.getElementById(`Birhataolustu`).innerHTML)

            }
        })();

        const action = 'Mola sonlandırıldı.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    };
    const fillData = (event) => {
        event?.target?.innerText === "C" ? setInputValue("") : setInputValue(prevValue => (prevValue += event?.target?.innerText));
        window.sendDTMF(1, event?.target?.innerText)
    }

    const numPadScreen = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"]

    const items = [
        {
            label: <Menu>
                <Row gutter={[0, 8]} style={{ width: "175px", textAlign: "center", alignItems: "center" }} >
                    {
                        numPadScreen?.map((item, index) => (
                            <Col key={index} span={8}><Button key={index + 1} onClick={fillData}>{item}</Button></Col>
                        ))
                    }
                </Row>
            </Menu>,
            key: '0',
        }
    ];

    const handleVisibleChange = (flag) => {
        setVisible(flag);

        const action = 'Telefon num-look açıldı.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    }

    const onChangePhoneInput = e => {
        if (e) {
            let value = e?.target?.value;
            let dtmfValue = value?.slice(-1);
            setInputValue(value);
            if (value) {
                if (dtmfValue && dtmfValue !== " ") {
                    if (Object.is(+dtmfValue, NaN)) {
                        if (dtmfValue === "*" || dtmfValue === "#") {
                            window.sendDTMF(1, dtmfValue)
                        }
                    } else {
                        window.sendDTMF(1, dtmfValue)
                    }
                }
            }
        }
    }
    
    return (
        <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
            <span style={{ display: "none", width: "0px" }} id="sure">{setLocale(true, 'rapor.Sure')}</span>
            <span style={{ display: "none", width: "0px" }} id="adet">{setLocale(true, 'Adet')}</span>


            <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
                <label style={{ display: "none" }} id="MolaBitmistir">{setLocale(true, 'MolaBitmistir')}</label>

                <Logo logoType={navMode} />
                <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
                    <div className="nav-left">
                        <Menu mode="horizontal">
                            {
                                isNavTop && !isMobile ?
                                    null
                                    :
                                    <Menu.Item key="0" onClick={() => { onToggle() }}>
                                        {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                                    </Menu.Item>
                            }
                        </Menu>

                        <Row id="header-row">
                            <Col id="header-input-col" flex={2} xs={24}>
                                <Input value={inputValue} onChange={onChangePhoneInput} id="phone" onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        ara()
                                    }
                                }}></Input>
                            </Col>

                            <Col flex={1} className="header-icon" id="first-icon"><Tooltip title={setLocale(true, 'Ara')}><PhoneOutlined onClick={() => ara()} /></Tooltip></Col>
                            <Col flex={1} className="header-icon"><Tooltip title={setLocale(true, 'Kapat')}><PhoneOutlined id="endButton" onClick={() => endCall()} style={{ color: 'rgb(187, 64, 51)' }} /></Tooltip></Col>
                            <Col flex={1} className="header-icon"><Tooltip title={setLocale(true, 'IptalEt')}><PhoneOutlined id="cancelButton" onClick={() => cancelCall()} style={{ fontSize: '25px', color: 'rgb(187, 64, 51)', paddingRight: '10px', display: "none" }} /></Tooltip></Col>
                            <Col flex={1} className="header-icon"><Tooltip title={setLocale(true, 'Beklet')}><PauseCircleOutlined onClick={() => holdSession()} name="hold-btn" /></Tooltip></Col>
                            <Col flex={1} className="header-icon"><Tooltip title={setLocale(true, 'DevamEt')}><PauseCircleOutlined onClick={() => unholdSession()} name="unhold-btn" style={{ fontSize: '25px', paddingRight: '10px', color: 'red', display: "none" }} /></Tooltip></Col>
                            <Col flex={1} className="header-icon"><Tooltip title={setLocale(true, 'MikrofonKapat')}><AudioMutedOutlined name="mute-btn" onClick={() => muteSession()} /></Tooltip></Col>
                            <Col flex={1} className="header-icon"><Tooltip title={setLocale(true, 'MikrofonAc')}><AudioMutedOutlined name="unmute-btn" onClick={() => unmuteSession()} style={{ fontSize: '25px', paddingRight: '10px', color: 'red', display: "none" }} /></Tooltip></Col>
                            <Col flex={1} className="header-icon"><Tooltip title="Transfer"><ApiOutlined onClick={() => transferCall()} /></Tooltip></Col>

                            <Col flex={1} className="header-icon">
                                <Tooltip title={setLocale(true, 'NumaratorAc')}>
                                    <Dropdown onOpenChange={handleVisibleChange} open={visible} placement="bottomLeft" menu={{ items }} trigger={["click"]}>
                                        <AppstoreOutlined />
                                    </Dropdown>
                                </Tooltip>
                            </Col>

                            {
                                recordStatus === false ? ( <Col flex={1} className="header-icon"><Tooltip title={setLocale(true, 'SesKaydiniBaslat')}><SaveOutlined name='Record-btn' onClick={() => startRe()} /></Tooltip></Col>)
                                : (<Col flex={1} className="header-icon"><Tooltip title={setLocale(true, 'SesKaydiniDurdur')}><SaveOutlined name='stopRecord-btn' onClick={() => stopRe()} style={{ color: 'rgb(187, 64, 51)'}} /></Tooltip></Col>
                                )
                            }

                        </Row>
                        <div id="callNumber"></div>
                        <div id="callStatus" style={{ marginLeft: "1rem" }}></div>
                        <div id="callTimer" style={{ marginLeft: "1rem" }}></div>

                        <StatusModal setVisible={setdurumVisible} visible={durumVisible} setstatus={setstatus} status={status} degistir={degistir} setdegistir={setdegistir}></StatusModal>
                    </div>
                    <div className="nav-right">
                        {breakVisible && <div id="header-second-row">
                            <CoffeeOutlined className="mola-icon" />
                            <span className="mola-icon">|</span>

                            {
                                !status ?
                                    <span className="mola-time">{setLocale(true, 'Adet')}{molaadet}  {setLocale(true, 'rapor.Sure')}{molasure}</span>
                                    : <span className="mola-time"> {mola}</span>
                            }
                            {
                                !status ?
                                    null
                                    : <span className="mola-icon">|</span>
                            }
                            {
                                !status ?
                                    null
                                    : <DisplayTimer className="mola-time" time={time} />

                            }

                            <span className="mola-icon">|</span>

                            {
                                !status ?
                                    <Tooltip title={setLocale(true, 'MolaBaslat')}><SelectOutlined visible="true" className="mola-icon" onClick={() => showdurumModal()} /></Tooltip>
                                    : <Tooltip title={setLocale(true, 'MolaBitir')}><CloseSquareOutlined className="mola-icon" onClick={() => showAktif()} /></Tooltip>
                            }
                            {
                                !status ?
                                    null
                                    : <Tooltip title={setLocale(true, 'MolaDegistir')}><RetweetOutlined className="mola-icon" onClick={() => showdurumdegistirModal()} /></Tooltip>
                            }
                        </div>}
                        <NavProfile />
                        <NavPanel />
                    </div>
                    <NavSearch active={searchActive} close={onSearchClose} />
                </div>
            </div>
            <Modal
                title="Transfer Call"
                open={true}
                onCancel={handleCancel}
                footer={null}
                className="transferModal"
                wrapClassName="transferModal"
                id="transferModal"
            >
                <div id="callTransferContainer">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <p style={{ margin: "0", color: "black" }}>{setLocale(true, 'TransferNumarasi')}</p>
                        <Input value={transferInputValue} onChange={(event) => setTransferInputValue(event.target.value)} placeholder="Transfer No" style={{ width: "70%" }} id="transferNoInput"></Input>
                    </div>
                    <p className="transferNoInputError" style={{ color: "red", display: "none" }}>{setLocale(true, 'Lutfenuyguntelefonnumarasigiriniz')}</p>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", marginTop: "2rem" }}>
                        <Button type="danger" style={{ display: "flex", alignItems: "center" }} onClick={blindTransfer}><RightOutlined />{setLocale(true, 'KorTransfer')}</Button>
                        <Button type="danger" style={{ display: "flex", alignItems: "center" }} onClick={attentedTransfer}><DoubleRightOutlined />{setLocale(true, 'KatildigiTransfer')}</Button>
                    </div>
                </div>
                <div id="transferAttentedCalling" style={{ display: "none" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <p id="transferAttentedCallingText" style={{ margin: "0", color: "black" }}></p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", marginTop: "2rem" }}>
                        <Button id="cancelAttendedCalling" type="danger" >{setLocale(true, 'IptalEt')}</Button>
                    </div>
                </div>
                <div id="transferAttentedAnswered" style={{ display: "none" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", marginTop: "2rem" }}>
                        <div>
                            <Button id="completeAttendedTransfer" type="danger">{setLocale(true, 'TransferiTamamla')}</Button>
                        </div>
                        <div>
                            <Button id="terminateAttendedTransfer" type="danger">{setLocale(true, 'TransferiIptalEt')}</Button>
                        </div>
                    </div>
                </div>

            </Modal>
        </Header>
    )
}

const mapStateToProps = ({ theme }) => {
    const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme } = theme;
    return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme }
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav);