import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

export const AppViews = () => {
	return (
		<Suspense fallback={<Loading cover='content' />}>
			<Outlet />
		</Suspense>
	);
};

export default React.memo(AppViews);
