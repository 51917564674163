import React, { createContext, useState } from 'react';
import ls from 'localstorage-slim';

const CounterContext = createContext(null);

export const CounterProvider = ({ children }) => {

  const [time, setTime] = useState(0);
  const [mola, setmola] = useState();
  const [molaadet, setmolaadet] = useState();
  const [molasure, setmolasure] = useState();
  const language = ls.get("langs", { decrypt: true })

  let lang = 'tr';
  if (language === "de" || language === "tr" || language === "en") {
    lang = language;
  }
  const [langs, setlangs] = useState(lang);

  const [logo, setlogo] = useState("43%");
  const [logomargin, setlogomargin] = useState("30%");


  const [molalist, setmolalist] = useState([]);
  const [status, setstatus] = useState(false);

  const [moladisabled, setmoladisabled] = useState(true);
  const values = { molaadet, setmolaadet, molasure, setmolasure, langs, setlangs, logomargin, setlogomargin, logo, setlogo, time, setTime, mola, setmola, moladisabled, setmoladisabled, molalist, setmolalist, status, setstatus }

  return (
    <CounterContext.Provider value={values}>{children}</CounterContext.Provider>
  );
};

export default CounterContext