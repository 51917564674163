import React, {useCallback, useEffect, useState} from 'react';
import { Route, Routes} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import Views from './views';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import { KategoriProvider } from './contexts/KategoriContext'
import { CounterProvider } from 'contexts/CounterContext';
import baseService from 'services/BaseService';
import { QueueRefresherProvider } from 'contexts/QueueRefresherContext';
import { Modal } from 'antd';
import ls from 'localstorage-slim';
import { env } from "./configs/EnvironmentConfig";
import CryptoJS from "crypto-js";

const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
    const { confirm } = Modal;
    const [isAppVisible, setIsAppVisible] = useState(false)

    const showConfirm = useCallback( () => {
        confirm({
            title: 'Uyarı',
            content: 'Bu sayfa başka bir sekmede aktif olarak görünüyor. Aktif sekmeleri kapatmak istiyor musunuz?',
            onOk() {
                ls.remove("auth_token");
                window.location.reload(false);
            },
            okText: 'Evet',
            cancelText: 'Hayır',
        });
    }, [confirm]);

    useEffect(() => {
        let token = ls.get("auth_token", { decrypt: true });
        const sipScript = document.createElement('script');
        const setcallScript = document.createElement('script');
        const momentScript = document.createElement('script');
        const localStorageScript = document.createElement('script');

        if (token) {
            showConfirm()
        } else {
            if (env.APP_FILE_SYSTEM === 'remote') {
                fetchAndAppendScript(env.APP_REMOTE_URL + '/static/js/sip-0.20.0.min.js', {}, "text");
                fetchAndAppendScript(env.APP_REMOTE_URL + '/conversion/static/js/setcallMain.js', {
                    apiUrl: env.API_ENDPOINT_URL,
                    baseUrl: env.APP_BASEURL,
                    baseName: env.APP_BASENAME,
                    wssServerUrl: env.APP_WSS_SERVER_URL
                }, "json");
                fetchAndAppendScript(env.APP_REMOTE_URL + '/static/js/moment-with-locales-2.24.0.min.js', {}, "text");
                fetchAndAppendScript(env.APP_REMOTE_URL + '/static/js/localstorage-slim.js', {}, "text");
            } else {
                sipScript.src = `${process.env.PUBLIC_URL}/js/sip-0.20.0.min.js`;
                sipScript.async = true;
                document.body.appendChild(sipScript);

                setcallScript.src = `${process.env.PUBLIC_URL}/js/setcallMain.js`;
                setcallScript.async = true;
                document.body.appendChild(setcallScript);

                momentScript.src = `${process.env.PUBLIC_URL}/js/moment-with-locales-2.24.0.min.js`;
                momentScript.async = true;
                document.body.appendChild(momentScript);

                localStorageScript.src = `${process.env.PUBLIC_URL}/js/localstorage-slim.js`;
                localStorageScript.async = true;
                document.body.appendChild(localStorageScript);
            }

            setIsAppVisible(true);
        }

        return () => {
            document.body.removeChild(sipScript);
            document.body.removeChild(setcallScript);
            document.body.removeChild(momentScript);
            document.body.removeChild(localStorageScript);
        }
    }, [showConfirm])

    const fetchAndAppendScript = (baseUrl, queryParams, type = 'text') => {
        if (typeof queryParams !== 'object' || queryParams === null) {
            queryParams = {};
        }

        const url = new URL(baseUrl);
        Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    if (response.status !== 200) {
                        throw new Error("License Expired...");
                    } else {
                        return showLicenseExpiredAlert();
                    }
                }
                return type === 'text' ? response.text() : response.json();
            })
            .then(async (script) => {
                const scriptElement = document.createElement('script');
                scriptElement.innerHTML = (script.iv && script.encryptedData) ? await decryptData(script.encryptedData, script.iv) : script;
                document.body.appendChild(scriptElement);
            })
            .catch((error) => {
                console.error('Error fetching or appending script:', error)
                return showLicenseExpiredAlert();
            })

        function showLicenseExpiredAlert() {
            return confirm({
                title: 'Uyarı',
                content: 'Lisansınızın süresi dolmuştur. Lütfen bizimle iletişime geçin.',
                onOk() {
                    window.open("https://setcall.com.tr/iletisim/", "_blank");
                },
                okText: 'Evet',
                cancelText: 'Hayır',
            });
        }

        async function decryptData(encryptedData, iv) {
            const keyValue = env.APP_REMOTE_KEY;
            const key = CryptoJS.enc.Hex.parse(keyValue);
            const decipher = CryptoJS.AES.decrypt(
                { ciphertext: CryptoJS.enc.Hex.parse(encryptedData) },
                key,
                { iv: CryptoJS.enc.Hex.parse(iv), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
            );

            const decryptedData = decipher.toString(CryptoJS.enc.Utf8);

            return JSON.parse(decryptedData);
        }
    };

    window.addEventListener("beforeunload", async function() {
        if (!window.location.href.includes("auth/login")) {
            const action = 'Sayfa Yenilendi.';

            await baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });

            await logOut();
        }
    });

    window.onbeforeunload = function () {
        if (!window.location.href.includes("auth/login")) {
            logOut();
        }
    };

    const logOut = async () => {
        if (window?.endSession) {
            await window.endSession(1);
        }

        if (window?.Unregister) {
            await window.Unregister();
        }

        let token = ls.get("auth_token", { decrypt: true });

        if (token) {
            let id = ls.get("id", { decrypt: true });
            baseService.setPost("api/userbreak/finish/" + id)
            baseService.setPost("logout/" + id + "/" + false);
            ls.remove("auth_token");
            var start = new Date().getTime();
            while (new Date().getTime() < start + 1000);
        }
        let statusControlWidget = ls.get("statusControlWidget", { decrypt: true });
        if (statusControlWidget) {
            ls.remove("statusControlWidget");
        }
    }

    return (
        <div className={isAppVisible ? "App" : "centered-logo"} >
            <QueueRefresherProvider>
                <KategoriProvider>
                    <CounterProvider>
                        <Provider store={store}>
                            <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
                                <Routes>
                                    <Route path="/*" element={isAppVisible ? <Views /> : <img src={`${process.env.PUBLIC_URL}/img/setcall-logo-splash.png`} alt="Setcall Logo" />} />
                                    <Route path="*" element={<img src={`${process.env.PUBLIC_URL}/img/setcall-logo-splash.png`} alt="Setcall Logo" />} />
                                </Routes>
                            </ThemeSwitcherProvider>
                        </Provider>
                    </CounterProvider>
                </KategoriProvider>
            </QueueRefresherProvider>
        </div>
    );
}

export default App;