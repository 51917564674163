import React, { useEffect, useState } from 'react';
import IntlMessage from "../../util-components/IntlMessage";
import ls from 'localstorage-slim';
import {Switch} from "antd";
import baseService from "../../../services/BaseService";

const StatusControlWidgetChecked = () => {
    const [isLocaleOn, setIsLocaleOn] = useState(true);
    const [localeKey, setLocaleKey] = useState("");

    const setLocale = (isLocaleOn, localeKey) =>
        isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

    useEffect(() => {
        const storedData = ls.get("statusControlWidget", { decrypt: true });

        if (storedData) {
            setLocaleKey(storedData.localeKey);
            setIsLocaleOn(storedData.isLocaleOn);
        }
    }, []);

    useEffect(() => {
        const data = { localeKey, isLocaleOn };
        ls.set("statusControlWidget", data, { encrypt: true });
    }, [localeKey, isLocaleOn]);

    const handleToggle = () => {
        setIsLocaleOn(!isLocaleOn);

        const action = `Sunucu durumu widget ${ isLocaleOn ? 'Gizle' : 'Göster' } olarak değiştirildi.`;

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    };

    return (
        <div>
            <Switch
                checked={isLocaleOn}
                checkedChildren={setLocale(true, 'status.control.Goster')}
                unCheckedChildren={setLocale(true, 'status.control.Gizle')}
                onChange={() => {
                    handleToggle()
                }}
            />
        </div>
    );
};

export default StatusControlWidgetChecked;
