import enLang from './entries/en_US';
import trLang from './entries/tr_TR';
import frLang from './entries/fr_FR';
import deLang from './entries/de_DE'

const AppLocale = {
    en: enLang,
    tr: trLang,
    fr: frLang,
    de: deLang
};

export default AppLocale;