import React, { createContext, useState } from 'react';

const QueueRefresherContext = createContext(null);

export const QueueRefresherProvider = ({ children }) => {

    const [queueRefresher, setQueueRefresher] = useState(false);

    const values = {
        queueRefresher,
        setQueueRefresher
    }

    return (
        <QueueRefresherContext.Provider value={values}>
            {children}
        </QueueRefresherContext.Provider>
    );
};

export default QueueRefresherContext