import React from 'react'
import { Form, Button, Input, Modal } from 'antd';
import BaseService from 'services/BaseService'
import IntlMessage from "../../util-components/IntlMessage";
import ls from 'localstorage-slim';
import baseService from "services/BaseService";

const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
function success(message) {
    Modal.success({
        content: message,
    });
}

function error(message) {
    Modal.error({
        content: message,
    });
}

function ChangePwModal(props) {

    const [changePwForm] = Form.useForm();
    const id = ls.get("id", { decrypt: true })
    const handleOk = async (values) => {
        let result = Object.assign({}, values, false, { id: id });
        let response = await BaseService.setPost("api/user/updatepassword/", result)
        if (response === "Başarılı") {
            props.setVisible(false)
            changePwForm.resetFields();
            success( document.getElementById(`Sifrenizguncellenmistir`).innerHTML);

        } else {
            error( document.getElementById(`Sifrenizguncellenememistir`).innerHTML);
        }

        const action = 'Şifre değiştirme işlemi yapıldı.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    };

    const handleCancel = e => {
        props.setVisible(false);

        const action = 'Şifre değiştirme alanı kapatıldı.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    };

    return (
        <div>
            <label style={{ display: "none" }} id="Sifrenizguncellenmistir">{setLocale(true, 'Sifrenizguncellenmistir')}</label>
            <label style={{ display: "none" }} id="Sifrenizguncellenememistir">{setLocale(true, 'Sifrenizguncellenememistir')}</label>

            <Modal
                title={setLocale(true, 'SifreDegistir')}
                open={props?.visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
            >
                <Form
                    name="changePasswordForm"
                    layout="vertical"
                    form={changePwForm}
                    onFinish={handleOk}
                >
                    <Form.Item
                        label={setLocale(true, 'EskiSifre')}
                        name="currentPassword"
                        rules={[{
                            required: true,
                            message: setLocale(true, 'Bualanzorunludur')
                        }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label={setLocale(true, 'YeniSifre')}
                        name="password"
                        rules={[{
                            required: true,
                            message: setLocale(true, 'Bualanzorunludur')

                        }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label={setLocale(true, 'YeniSifreTekrar')}
                        name="confirmPassword"
                        rules={
                            [
                                {
                                    required: true,
                                    message: setLocale(true, 'Bualanzorunludur')

                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(setLocale(true, 'Girdiginizsifrelereslesmiyor'))
                                    },
                                }),
                            ]
                        }
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item style={{ marginTop: "4rem", textAlign: "right" }}>
                        <Button type="primary" htmlType="submit" >
                            {setLocale(true, 'SifreDegistir')}

                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    )
}

export default ChangePwModal
