import React from 'react'
import { APP_NAME } from 'configs/AppConfig';
import IntlMessage from "../util-components/IntlMessage";

const setLocale = (isLocaleOn, localeKey) =>
isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
export default function Footer() {
	return (
		<footer className="footer">
			<span>{setLocale(true, 'TelifHakki')}  &copy;  {`${new Date().getFullYear()}`} <a href="https://setcall.com.tr" className="font-weight-semibold" target="_blank" rel="noreferrer">{`${APP_NAME}`}</a> {setLocale(true, 'Tümhaklarisaklidir')}</span>
		</footer>
	)
}

