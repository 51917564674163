import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from "react-redux";
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from 'constants/ThemeConstant';
import { APP_NAME } from 'configs/AppConfig';
import utils from 'utils';
import { Grid } from 'antd';
import CounterContext from "contexts/CounterContext";
import ls from 'localstorage-slim';
const { useBreakpoint } = Grid;


const GetLogoWidthGutter = (props, isMobile) => {

    // const [, setdurumVisible] = useState(false)

    const { navCollapsed, navType } = props;
    const isNavTop = navType === NAV_TYPE_TOP ? true : false
    if (isMobile && !props.mobileLogo) {
        return 0
    }
    if (isNavTop) {
        return 'auto'
    }
    if (navCollapsed) {
        return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
        return `${SIDE_NAV_WIDTH}px`
    }
}

const getLogo = (props) => {
    const { navCollapsed, logoType } = props;
    if (logoType === 'light') {
        if (navCollapsed) {
            return 'assets/img/setcall-logo-header-open.png'
        }
        return `${process.env.PUBLIC_URL}/img/setcall-logo-header-close.png`
    }

    if (navCollapsed) {
        return `${process.env.PUBLIC_URL}/img/setcall-logo-header-close.png`
    }
    return `${process.env.PUBLIC_URL}/img/setcall-logo-header-open.png`
}

const getLogoDisplay = (isMobile, mobileLogo) => {
    if (isMobile && !mobileLogo) {
        return 'd-none'
    } else {
        return 'logo'
    }
}



export const Logo = (props) => {
    let navigate = useNavigate();
    const userRole = ls.get("role", { decrypt: true });

    const { logo } = useContext(CounterContext)
    const { logomargin } = useContext(CounterContext)
    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
    const logoClick = () => {
        userRole === 'AGENT' ? navigate(`/app/dashboard/agent`) : navigate(`/app/dashboard/supervisor`)
    }
    return (
        <div
            className={getLogoDisplay(isMobile, props.mobileLogo)}
            style={{ width: `${GetLogoWidthGutter(props, isMobile)}` }}>

            <img src={getLogo(props)} style={{ width: logo, marginLeft: logomargin, cursor: "pointer" }} alt={`${APP_NAME} logo`} onClick={logoClick} />

        </div>
    )
}

const mapStateToProps = ({ theme }) => {
    const { navCollapsed, navType } = theme;
    return { navCollapsed, navType }
};

export default connect(mapStateToProps)(Logo);
