import React, { useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message, Space } from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import baseService from 'services/BaseService';
import ls from "localstorage-slim";

const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const NavAudioDevice = () => {
    const [audioDevices, setAudioDevices] = useState([]);
    const [selectedAudioDeviceType, setSelectedAudioDeviceType] = useState(null);

    useEffect(() => {
        getAudioDevices();
    }, []);

    useEffect(() => {
        const storedDevice = ls.get('UsingAudioDevice', { decrypt: true });
        const storedDeviceId = ls.get('UsingAudioDeviceId', { decrypt: true });

        if (storedDevice && storedDeviceId) {
            const matchingDevice = audioDevices.find(device => device.deviceId === storedDeviceId);
            if (matchingDevice) {
                startUsingAudioDevice(matchingDevice.deviceId);
                setSelectedAudioDeviceType(storedDevice);
            }
        }
    }, [audioDevices]);

    const getAudioDevices = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioInputDevices = devices.filter(device => device.kind === 'audiooutput');
            setAudioDevices(audioInputDevices);
        } catch (error) {
            console.error('Ses cihazları alınırken bir hata oluştu:', error);
        }
    };

    const startUsingAudioDevice = async (deviceId) => {
        try {
            const constraints = { audio: { deviceId: { exact: deviceId } } };
            await navigator.mediaDevices.getUserMedia(constraints);
        } catch (error) {
            console.error('Ses cihazı kullanılırken bir hata oluştu:', error);
        }
    };

    const handleDeviceSelect = (deviceId, deviceType) => {
        startUsingAudioDevice(deviceId);
        setSelectedAudioDeviceType(deviceType);

        ls.set('UsingAudioDevice', deviceType, { encrypt: true });

        ls.set('UsingAudioDeviceId', deviceId, { encrypt: true });

        const action = `Telefon ses cihazı ${deviceType ? deviceType : 'default'} olarak değiştirildi.`;

        baseService.createLog(`api/logs/create`, action).then().catch((err) => {
            console.log(err)
        });

        message.success(`${action}`);
    };

    const items = [
        {
            label:  <Menu>
                {audioDevices.length > 0 && audioDevices.map(device => (
                    <Menu.Item key={device.deviceId} onClick={() => handleDeviceSelect(device.deviceId, device.label)}>
                        {device.label ? device.label : setLocale(true, 'phone.settings.default') }
                    </Menu.Item>
                ))}
            </Menu>,
            key: '0',
        }
    ];

    return (
        <div>
            {audioDevices.length > 0 ? (
                <Dropdown menu={{ items }} trigger={['click']}>
                    <Space>
                        {selectedAudioDeviceType ? selectedAudioDeviceType : setLocale(true, 'phone.settings.default')}
                        <DownOutlined />
                    </Space>
                </Dropdown>
            ) : (
                setLocale(true, 'phone.settings.default')
            )}
        </div>
    );
};

export default NavAudioDevice;
