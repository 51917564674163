const config = {
	APP_NAME: process.env.REACT_APP_NAME,
	APP_LANG: process.env.REACT_APP_LANG,
	APP_BASEURL: process.env.REACT_APP_BASEURL,
	APP_BASENAME: process.env.REACT_APP_BASENAME,
	API_ENDPOINT_URL: process.env.REACT_APP_API_END_POINT,
	APP_FILE_SYSTEM: process.env.REACT_APP_FILE_SYSTEM,
	APP_REMOTE_KEY: process.env.REACT_APP_REMOTE_KEY,
	APP_REMOTE_URL: process.env.REACT_APP_REMOTE_URL,
	APP_WSS_SERVER_URL: process.env.REACT_APP_WSS_SERVER_URL
};

export const env = config;