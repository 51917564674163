import React, { useEffect, useState } from 'react';
import socketIOClient from "socket.io-client";
import {ENDPOINT} from "../../../configs/AppConfig";
import IntlMessage from "../../util-components/IntlMessage";
import ls from 'localstorage-slim';
import {message, Modal} from "antd";
import baseService from "../../../services/BaseService";
import moment from "moment";
const StatusControlWidget = () => {
    const { confirm } = Modal;

    const setLocale = (isLocaleOn, localeKey) =>
        isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

    const [isOnline, setIsOnline] = useState(true);
    const [connectionType, setConnectionType] = useState('');
    const [isServerActive, setServerActive] = useState(false);

    const [isWebPhone, setWebPhone] = useState(false);
    const [isStatusControlWidget, setStatusControlWidget] = useState(false);

    useEffect(() => {
        const storedWebPhoneData = ls.get("webphone", { decrypt: true });

        if (storedWebPhoneData && storedWebPhoneData === Number(1)) {
            setWebPhone(true);
        } else {
            setWebPhone(false);
        }
    }, [setWebPhone]);

    useEffect(() => {
        const checkLocalStorage = () => {
            const storedData = ls.get("statusControlWidget", { decrypt: true });

            if (!storedData) {
                ls.set("statusControlWidget",  { localeKey: "", isLocaleOn: true }, { encrypt: true });
            }

            if (storedData && storedData.isLocaleOn === true) {
                setStatusControlWidget(true);
            } else {
                setStatusControlWidget(false);
            }
        };

        checkLocalStorage();

        const handleStorageChange = (event) => {
            if (event.key === "statusControlWidget") {
                checkLocalStorage();
            }
        };

        window.addEventListener("storage", handleStorageChange);

        const interval = setInterval(checkLocalStorage, 1000);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const confirmCustomModal = (modalTitle, modalContent) => {
            confirm({
                title: modalTitle,
                content: modalContent,
                okButtonProps: {
                    style: {
                        display: 'none'
                    }
                },
                cancelButtonProps: {
                    style: {
                        display: 'none'
                    }
                }
            });
        };

        const handleProcessCompleted = () => {
            const cancelModal = document.querySelectorAll('.ant-modal-root.css-dev-only-do-not-override-ed5zg0');
            cancelModal.forEach((element) => {
                const elementFindText = element.querySelector('.ant-modal-confirm-content');
                if (elementFindText) {
                    const elementText = elementFindText.innerText;
                    if (elementText) {
                        if (elementText === 'Sunucu bağlantısı kesildi.' || elementText === 'İnternet bağlantısı kesildi.') {
                            element.remove();
                        }
                    }
                }
            });
        };

        const checkOnlineStatus = async () => {
            await setIsOnline(navigator.onLine);

            if(isOnline) {

                message.success({
                    title: 'Bilgi',
                    content: 'İnternet bağlandı.'
                });

                handleProcessCompleted();
            }

            await onlineNetworkAPI();
        };

        const onlineNetworkAPI = async () => {
            const action = await JSON.parse(ls.get('log_network_control', { decrypt: true }));

            if (action) {
                await baseService.createLog(`api/logs/create`, `internet bağlantısı kesildi.&dateTime=${action.dateTime}`, action.userId).then().catch((err) => {
                    console.log(err)
                });

                ls.remove('log_network_control');

                await baseService.createLog(`api/logs/create`, 'İnternet bağlandı.').then().catch((err) => {console.log(err)});
            }
        };

        if (isOnline) {
            onlineNetworkAPI();
        }

        const checkOfflineStatus = () => {
            const userId = ls.get('id', { decrypt: true });

            const dateFormat = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

            const userData = {
                userId: userId,
                dateTime: dateFormat
            };

            ls.set('log_network_control', JSON.stringify(userData), { encrypt: true });

            setIsOnline(navigator.onLine);

            if(!isOnline) {
                confirmCustomModal('Uyarı', 'İnternet bağlantısı kesildi.');
            }
        };

        const checkConnectionType = () => {
            const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
            if (connection) {
                setConnectionType(connection.effectiveType);
            }
        };

        const checkOnlineNetworkModalControl = () => {
            if(isOnline) {

                message.success({
                    title: 'Bilgi',
                    content: 'İnternet bağlandı.'
                });

                handleProcessCompleted();
            }
        };
        checkOnlineNetworkModalControl();

        checkOfflineStatus();
        window.addEventListener('online', checkOnlineStatus);
        window.addEventListener('offline', checkOfflineStatus);

        checkConnectionType();
        window.addEventListener('change', checkConnectionType);


        const socket = socketIOClient(ENDPOINT);

        socket.on('connect', async () => {
            setServerActive(true);

            message.success({
                title: 'Bilgi',
                content: 'Sunucu bağlandı.'
            });

            handleProcessCompleted();

            const action = await JSON.parse(ls.get('log_server_control', { decrypt: true }));

            if (action) {
                await baseService.createLog(`api/logs/create`, `sunucu bağlantısı kesildi.&dateTime=${action.dateTime}`, action.userId).then().catch((err) => {console.log(err)});

                ls.remove('log_server_control');

                await baseService.createLog(`api/logs/create`, 'Sunucu bağlandı.').then().catch((err) => {console.log(err)});
            }
        });

        socket.on('disconnect', () => {
            const userId = ls.get('id', { decrypt: true });

            const dateFormat = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

            const userData = {
                userId: userId,
                dateTime: dateFormat
            };

            ls.set('log_server_control', JSON.stringify(userData), { encrypt: true });

            setServerActive(false);

            confirmCustomModal('Uyarı', 'Sunucu bağlantısı kesildi.');
        });

        return () => {
            window.removeEventListener('online', checkOnlineStatus);
            window.removeEventListener('offline', checkOfflineStatus);
            window.removeEventListener('change', checkConnectionType);

            socket.connect();
            socket.disconnect();
        };
    }, [confirm, isOnline]);

    return (
        <div className={`sticky-top w-100 ${(isStatusControlWidget ? 'd-block' : 'd-none')}`} style={{top: 70,marginTop: '-20px', zIndex: 1}}>
            <div className={'container-fluid pr-0 pl-0 mr-0 ml-0'}>
                <div className={'row'}>
                    <div className={'col-12 bg-white'}>
                        <div className="d-block mb-xl-auto d-xl-flex justify-content-xl-end my-auto">
                            <div className={'d-block mb-md-auto d-md-flex justify-content-start my-auto'}>
                                { isWebPhone ? (
                                    <div className={`my-auto text-dark small`}>
                                        <span className={'pl-2 pr-2'}>
                                            Telefon:
                                        </span>
                                        <span className={'sipStatusControl'}>
                                            <div className="ant-badge p-1 sipStatusControlType">
                                                Offline
                                            </div>
                                        </span>
                                    </div>
                                ) : null }

                                <div className={'my-auto text-dark small ml-3'}>
                                     <span className={'pl-2 pr-2'}>
                                         {setLocale(true, 'status.control.SunucuDurumu')} :
                                     </span>

                                    <span>
                                        <div className={`ant-badge p-1 text-white ${isServerActive ? 'bg-success' : 'bg-danger'}`}>
                                            {isServerActive ? setLocale(true, 'status.control.SunucuAktif') : setLocale(true, 'status.control.SunucuAktifDegil')}
                                        </div>
                                    </span>
                                </div>

                                <div className={'my-auto text-dark small ml-3'}>
                                     <span className={'pl-2 pr-2'}>
                                         {setLocale(true, 'status.control.InternetBaglantisi')} :
                                     </span>

                                    <span>
                                        <div className={`ant-badge p-1 text-white ${isOnline ? 'bg-success' : 'bg-danger'}`}>
                                            {isOnline ? setLocale(true, 'status.control.InternetBagli') : setLocale(true, 'status.control.InternetBagliDegil')}
                                        </div>
                                    </span>
                                </div>
                                <div className={'my-auto text-dark small ml-3'}>
                                     <span className={'pl-2 pr-2'}>
                                         {setLocale(true, 'status.control.BaglantiTuru')} :
                                     </span>

                                    <span>
                                        <div className={`ant-badge p-1 text-dark`}>
                                            {connectionType ? connectionType : setLocale(true, 'status.control.Bilinmiyor')}
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatusControlWidget;