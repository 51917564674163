import React, { useContext } from "react";
import { CheckOutlined, GlobalOutlined, DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from "antd";
import lang from "assets/data/language.data.json";
import { connect } from "react-redux";
import { onLocaleChange } from 'redux/actions/Theme'
import CounterContext from "contexts/CounterContext";
import ls from 'localstorage-slim';
import baseService from "../../services/BaseService";

function getLanguageDetail(locale) {
	const data = lang?.filter(elm => (elm.langId === locale))
	return data[0]
}

const SelectedLanguage = ({ locale }) => {
	const language = getLanguageDetail(locale)
	const { langName, icon } = language
	return (
		<div className="d-flex align-items-center">
			<img style={{ maxWidth: '20px' }} src={`${process.env.PUBLIC_URL}/img/flags/${icon}.png`} alt={langName} />
			<span className="font-weight-semibold ml-2">{langName} <DownOutlined className="font-size-xs" /></span>
		</div>
	)
}

export const NavLanguage = ({ locale, configDisplay, onLocaleChange }) => {
	const { setlangs } = useContext(CounterContext)
	function getsss(langId) {
		onLocaleChange(langId)
		setlangs(langId);
		ls.set('langs', langId, { encrypt: true })

		const action = `Sistem dili ${ls.get('langs', {encrypt: true})} değiştirildi.`;

		baseService.createLog(`api/logs/create`, action).then().catch((err) => {console.log(err)});
	}

	const items = [
		{
			label: <Menu>
				{
					lang?.map((elm, i) => {
						return (
							<Menu.Item
								key={i}
								className={locale === elm.langId ? 'ant-dropdown-menu-item-active' : ''}
								onClick={() => getsss(elm.langId)}


							>
							<span className="d-flex justify-content-between align-items-center">
								<div>
									<img style={{ maxWidth: '20px' }} src={`${process.env.PUBLIC_URL}/img/flags/${elm.icon}.png`} alt={elm.langName} />
									<span className="font-weight-normal ml-2">{elm.langName}</span>
								</div>
								{locale === elm.langId ? <CheckOutlined className="text-success" /> : null}
							</span>
							</Menu.Item>
						)
					})
				}
			</Menu>,
			key: '0',
		}
	];

	return (
		<Dropdown placement="bottomRight" menu={{ items }} trigger={["click"]}>
			{
				configDisplay ?
					(
						<a href="#/" className="text-gray" onClick={e => e.preventDefault()}>
							<SelectedLanguage locale={locale} />
						</a>
					)
					:
					(
						<Menu mode="horizontal">
							<Menu.Item key={'navLanguage'}>
								<a href="#/" onClick={e => e.preventDefault()}>
									<GlobalOutlined className="nav-icon mr-0" />
								</a>
							</Menu.Item>
						</Menu>
					)
			}
		</Dropdown>
	)
}

const mapStateToProps = ({ theme }) => {
	const { locale } = theme;
	return { locale }
};

export default connect(mapStateToProps, { onLocaleChange })(NavLanguage);
