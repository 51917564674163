import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle } from "redux/actions/Theme";
import ls from 'localstorage-slim';
import baseService from "../../services/BaseService";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr?.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  let role = ls.get("role", { decrypt: true });
  let yetkilimenu = ls.get("menu", { decrypt: true });

  const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  }
  const [openKeys, setOpenKeys] = useState([]);
  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    const keyArray = navigationConfig[0].submenu?.map(item => item.key);
    let subkeyArray = [];
    navigationConfig[0].submenu?.forEach(submenu => {
      submenu.submenu.map(i => subkeyArray.push(i.key))
    });
    keyArray.indexOf(latestOpenKey) === -1 ? setOpenKeys(keys) : setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    if (subkeyArray.includes(latestOpenKey)) {
      setOpenKeys([openKeys[0], latestOpenKey])
    }
  };

  const createLogMenu = (action) => {
    const menuTitle = action?.defaultTitle;
    const menuRoute = action?.path;

    if (menuTitle && menuRoute) {
      const menuAction = `title: ${ menuTitle }, path: '${ menuRoute }' bilgisine sahip menü görüntülendi.`;
      baseService.createLog(`api/logs/create`, menuAction).then(() => {}).catch((err) => { console.log(err) });
    }
  };

  return (
    <Menu
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
      onClick={createLogMenu(routeInfo)}
    >
      {navigationConfig?.map((menu) =>
        menu.submenu?.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >

            {menu.submenu?.map((subMenuFirst) =>
            (role === "ADMIN" && ["6spy", "98karaliste", "97crm", "7rapor-supervisor", "50dashboard2", "99sistem", "96musteri-listesi", "2cagrigecmisi", "100logs"].includes(subMenuFirst.key) ? subMenuFirst.submenu?.length > 0 ? (
              <SubMenu
                icon={
                  subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null
                }
                key={subMenuFirst.key}
                title={setLocale(localization, subMenuFirst.title)}
              >
                {subMenuFirst.submenu?.map((subMenuSecond) => (
                  subMenuSecond.submenu?.length < 1 ?
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? (
                        <Icon type={subMenuSecond?.icon} />
                      ) : null}
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                    </Menu.Item> : <SubMenu
                      icon={
                        subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null
                      }
                      key={subMenuSecond.key}
                      title={setLocale(localization, subMenuSecond.title)}
                    >
                      {subMenuSecond.submenu?.map((subMenuSecond2) => (
                        <Menu.Item key={subMenuSecond2.key}>
                          {subMenuSecond2.icon ? (
                            <Icon type={subMenuSecond2?.icon} />
                          ) : null}
                          <span>
                            {setLocale(localization, subMenuSecond2.title)}
                          </span>
                          <Link onClick={() => closeMobileNav()} to={subMenuSecond2.path} />
                        </Menu.Item>
                      ))}
                    </SubMenu>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={subMenuFirst.key} >
                {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                <span>{setLocale(localization, subMenuFirst.title)}</span>
                <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
              </Menu.Item>
            ) : role === "SUPERVISOR" && (
                [ yetkilimenu[0],
                  yetkilimenu[1],
                  yetkilimenu[2],
                  yetkilimenu[3],
                  yetkilimenu[4],
                  yetkilimenu[5],
                  yetkilimenu[6],
                  yetkilimenu[7],
                  yetkilimenu[8],
                  yetkilimenu[9],
                  yetkilimenu[10],
                  yetkilimenu[11],
                  yetkilimenu[12],
                  yetkilimenu[13],
                  yetkilimenu[14],
                  yetkilimenu[15],
                  yetkilimenu[16]
                ].includes(subMenuFirst.key)) ? subMenuFirst.submenu?.length > 0 ? (
              <SubMenu
                icon={
                  subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null
                }
                key={subMenuFirst.key}
                title={setLocale(localization, subMenuFirst.title)}>

                {subMenuFirst.submenu?.map((subMenuSecond) => (
                  (subMenuFirst.key !== "7rapor-supervisor" && subMenuFirst.key !== "50dashboard2") ||
                    (subMenuFirst.key === "7rapor-supervisor" &&
                        (
                            [ yetkilimenu[0],
                              yetkilimenu[1],
                              yetkilimenu[2],
                              yetkilimenu[3],
                              yetkilimenu[4],
                              yetkilimenu[5],
                              yetkilimenu[6],
                              yetkilimenu[7],
                              yetkilimenu[8],
                              yetkilimenu[9],
                              yetkilimenu[10],
                              yetkilimenu[11],
                              yetkilimenu[12],
                              yetkilimenu[13],
                              yetkilimenu[14],
                              yetkilimenu[15],
                              yetkilimenu[16]
                            ].includes(subMenuSecond.key)
                        ))
                  || (subMenuFirst.key === "50dashboard2" &&
                      (
                          [ yetkilimenu[0],
                            yetkilimenu[1],
                            yetkilimenu[2],
                            yetkilimenu[3],
                            yetkilimenu[4],
                            yetkilimenu[5],
                            yetkilimenu[6],
                            yetkilimenu[7],
                            yetkilimenu[8],
                            yetkilimenu[9],
                            yetkilimenu[10],
                            yetkilimenu[11],
                            yetkilimenu[12],
                            yetkilimenu[13],
                            yetkilimenu[14],
                            yetkilimenu[15],
                            yetkilimenu[16]
                          ].includes(subMenuSecond.key)
                      )) ?
                    subMenuSecond.submenu?.length < 1 ?
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>
                          {setLocale(localization, subMenuSecond.title)}
                        </span>
                        <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                      </Menu.Item> : <SubMenu
                        icon={
                          subMenuSecond.icon ? (
                            <Icon type={subMenuSecond?.icon} />
                          ) : null
                        }
                        key={subMenuSecond.key}
                        title={setLocale(localization, subMenuSecond.title)}
                      >
                        {subMenuSecond.submenu?.map((subMenuSecond2) => (
                          <Menu.Item key={subMenuSecond2.key}>
                            {subMenuSecond2.icon ? (
                              <Icon type={subMenuSecond2?.icon} />
                            ) : null}
                            <span>
                              {setLocale(localization, subMenuSecond2.title)}
                            </span>
                            <Link onClick={() => closeMobileNav()} to={subMenuSecond2.path} />
                          </Menu.Item>
                        ))}
                      </SubMenu> : null
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={subMenuFirst.key}>
                {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                <span>{setLocale(localization, subMenuFirst.title)}</span>
                <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
              </Menu.Item>
            ) : role === "AGENT" &&  (
                [ yetkilimenu[0],
                  yetkilimenu[1],
                  yetkilimenu[2],
                  yetkilimenu[3],
                  yetkilimenu[4],
                  yetkilimenu[5],
                  yetkilimenu[6],
                  yetkilimenu[7]
                ].includes(subMenuFirst.key)
            ) ? subMenuFirst.submenu?.length > 0 ? (
              <SubMenu
                icon={
                  subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null
                }
                key={subMenuFirst.key}
                title={setLocale(localization, subMenuFirst.title)}
              >
                {subMenuFirst.submenu?.map((subMenuSecond) => (
                  <Menu.Item key={subMenuSecond.key}>
                    {subMenuSecond.icon ? (
                      <Icon type={subMenuSecond?.icon} />
                    ) : null}
                    <span>
                      {setLocale(localization, subMenuSecond.title)}
                    </span>
                    <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={subMenuFirst.key}>
                {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                <span>{setLocale(localization, subMenuFirst.title)}</span>
                <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
              </Menu.Item>
            ) : null)
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig?.map((menu) =>
        menu.submenu?.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu?.map((subMenuFirst) =>
              subMenuFirst.submenu?.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu?.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);