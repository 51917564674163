import React, { createContext, useState } from 'react';

const KategoriContext = createContext(null);

export const KategoriProvider = ({ children }) => {

    const [ckategori, setckategori] = useState(false);
   

    const values = {
       
      ckategori,
      setckategori
    }

    return (
        <KategoriContext.Provider value={values}>
            {children}
        </KategoriContext.Provider>
    );
};

export default KategoriContext