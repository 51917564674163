import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
export const AppViews = () => {
  return (
      <Suspense fallback={<Loading cover="page"/>}>
        <Outlet />
      </Suspense>
  )
}

export default AppViews;