import React from 'react'
import { connect } from 'react-redux'
import { toggleCollapsedNav } from 'redux/actions/Theme';
import NavLanguage from './NavLanguage';
import IntlMessage from "../util-components/IntlMessage";
import StatusControlWidgetChecked from "../custom-components/statusWidget/StatusControlWidgetChecked";
import NavSpeaker from './NavPhoneSettings/NavSpeaker';
import NavAudioDevice from './NavPhoneSettings/NavAudioDevice';
import NavMicrophone from './NavPhoneSettings/NavMicrophone';

const setLocale = (isLocaleOn, localeKey) =>
isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const ListOption = ({name, selector, disabled, vertical}) => (
	<div className={`my-4 ${vertical? '' : 'd-flex align-items-center justify-content-between'}`}>
		<div className={`${disabled ? 'opacity-0-3' : ''} ${vertical? 'mb-3' : ''}`}>{name}</div>
		<div>{selector}</div>
	</div>
)

export const ThemeConfigurator = () => {
	return (
		<>
			<div className="mb-3">
				<ListOption
					name={ setLocale(true, 'Dil')}
					selector={
						<NavLanguage configDisplay/>
					}
				/>

				<ListOption
					name={setLocale(true, 'status.control.SunucuDurumu')}
					selector={
						<StatusControlWidgetChecked />
					}
				/>
			</div>

			<div className="mb-3">
				<div className='text-primary border-bottom' style={{fontSize: '17px', fontWeight: 500}}>
					{setLocale(true, 'phone.settings.title')}
				</div>

				<ListOption
					name={setLocale(true, 'phone.settings.Speaker')}
					selector={
						<NavSpeaker />
					}
				/>

				<ListOption
					name={setLocale(true, 'phone.settings.AudioDevice')}
					selector={
						<NavAudioDevice />
					}
				/>

				<ListOption
					name={setLocale(true, 'phone.settings.Microphone')}
					selector={
						<NavMicrophone />
					}
				/>
			</div>
		</>
	)
}

const mapStateToProps = ({ theme }) => {
  const { navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme } =  theme;
  return { navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme }
};

const mapDispatchToProps = {
	toggleCollapsedNav,
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeConfigurator)
