
import { Form, Button, Modal, Select } from 'antd';
import BaseService from 'services/BaseService'
import React, { useState, useEffect, useContext } from "react";
import CounterContext from 'contexts/CounterContext';
import IntlMessage from "../../util-components/IntlMessage";
import ls from 'localstorage-slim';

const setLocale = (isLocaleOn, localeKey) =>
isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
function success(message) {
  Modal.success({
    content: message,
  });
}

function error(message) {
  Modal.error({
    content: message,
  });
}

function StatusModal(props) {

  const { setTime } = useContext(CounterContext)
  const { setmola } = useContext(CounterContext)
  const { setmoladisabled } = useContext(CounterContext)
 
  const { setstatus } = useContext(CounterContext)
  const { Option } = Select;
  const [changePwForm] = Form.useForm();
  const id = ls.get("id", { decrypt: true })
  const [list, setList] = useState([]);
  const [use, setuse] = useState(false);
  useEffect(() => {
    (async function () {
      let data = await BaseService.getPost("api/break");
      let data2 = await BaseService.getPost("api/kalanbreak/" + id);
      if (data?.length > 0 && data2?.length > 0) {

        data?.forEach(element => {
          data2?.forEach(element2 => {
            if (element?.ID === element2?.Status) {
              element.MaxAmountOfUser = element?.MaxAmountOfUser - element2?.adet;
            }

          });
        });
      }
      Array.isArray(data) && setList(data)
    })();
  }, [id, use]);

  const handleOk = async (values) => {
    if (props?.degistir) {
   
     
 
      list?.forEach(async element => {
        if (element?.ID === values?.status) {
          if (element?.MaxAmountOfUser < 1) {
            error(document.getElementById(`MolaHakkiBitti`).innerHTML)
          }
          else {

            let response = await BaseService.setPost("api/userbreak/finish/" + id)
            if (response === "Başarılı") {

              let response2 = await BaseService.setPost("api/userbreak/update/" + id)
              if (response2 === "Başarılı") {





                let result = Object.assign({}, values, false, { id: id });
                let response = await BaseService.setPost("api/userbreak/add/", result)
                if (response === "Başarılı") {
                  props.setVisible(false)
                  changePwForm.resetFields();
                  success(document.getElementById(`MolaDegistirilmistir`).innerHTML)

                  let response2 = await BaseService.setPost("api/userbreak/update/", result)
                  if (response2 === "Başarılı") {

                    setstatus(false)

                    props.setstatus(true)
                    list?.forEach(element => {
                      if (element?.ID === values?.status) {
                        let t = new Date();

                        t.setMinutes(t.getMinutes() + element?.BreakTime)

                        t = new Date(t).getTime();

                        setTime(0)
                        setTime(t)
                        setmola(element?.Name)
                        setmoladisabled(false)
                        setuse(!use)
                      }
                    });


                  }  else if(response2 === "Böyle bir kayıt mevcut değil."){
                    error(document.getElementById(`Boylebirkayitmevcutdegil`).innerHTML)
      
                  }
                  else {
                    error(document.getElementById(`Birhataolustu`).innerHTML)
                  }

                }   else if(response === "Böyle bir kayıt mevcut değil."){
                  error(document.getElementById(`Boylebirkayitmevcutdegil`).innerHTML)
    
                }
                else if(response === "Eksik bilgiler var."){
                  error(document.getElementById(`Eksikbilgilervar`).innerHTML)
    
                }
                else {
                  error(document.getElementById(`Birhataolustu`).innerHTML)
                }



              } else {
                error(document.getElementById(`Birhataolustu`).innerHTML)
              }

            } else if(response === "Bir hata oluştu."){
              error(document.getElementById(`Birhataolustu`).innerHTML)

            }
            else if(response === "Böyle bir kayıt mevcut değil."){
              error(document.getElementById(`Boylebirkayitmevcutdegil`).innerHTML)

            }
            else if(response === "Eksik bilgiler var."){
              error(document.getElementById(`Eksikbilgilervar`).innerHTML)

            }
            else{
              error(document.getElementById(`Birhataolustu`).innerHTML)

            }

          }
        }





      });


    } else {

      list?.forEach(async element => {
        if (element?.ID === values?.status) {
          if (element?.MaxAmountOfUser < 1) {
            error(document.getElementById(`MolaHakkiBitmistir`).innerHTML)
          }
          else {


            let result = Object.assign({}, values, false, { id: id });
            let response = await BaseService.setPost("api/userbreak/add/", result)
            if (response === "Başarılı") {
              props.setVisible(false)
              changePwForm.resetFields();
             
              success( document.getElementById(`MolaBaslamistir`).innerHTML)

              let response2 = await BaseService.setPost("api/userbreak/update/", result)
              if (response2 === "Başarılı") {

                let response = await BaseService.setPost("api/queue/pause/" + id + "/true")
                if (response === "Başarılı") {

                  props?.setstatus(true)
                  list?.forEach(element => {
                    if (element?.ID === values?.status) {
                      let t = new Date();

                      t.setMinutes(t.getMinutes() + element?.BreakTime)

                      t = new Date(t).getTime();

                      setTime(t)
                      setmola(element?.Name)
                      setmoladisabled(false)
                      setuse(!use)
                    }
                  });

                }
              } else if(response2 === "Böyle bir kayıt mevcut değil."){
                error(document.getElementById(`Boylebirkayitmevcutdegil`).innerHTML)
  
              }
              else {
                error(document.getElementById(`Birhataolustu`).innerHTML)
              }

            } else if(response === "Böyle bir kayıt mevcut değil."){
              error(document.getElementById(`Boylebirkayitmevcutdegil`).innerHTML)

            }
            else if(response === "Eksik bilgiler var."){
              error(document.getElementById(`Eksikbilgilervar`).innerHTML)

            }
            else {
              error(document.getElementById(`Birhataolustu`).innerHTML)
            }
          }
        }





      });
    }


  };

  const handleCancel = e => {
    props.setVisible(false)

  };

  return (
  <div>
  
      <label style={{display:"none"}} id="MolaBaslamistir">{setLocale(true, 'MolaBaslamistir')}</label>
      <label style={{display:"none"}} id="MolaHakkiBitti">{setLocale(true, 'MolaHakkiBitti')}</label>
      <label style={{display:"none"}} id="MolaDegistirilmistir">{setLocale(true, 'MolaDegistirilmistir')}</label>
      <label style={{display:"none"}} id="MolaHakkiBitmistir">{setLocale(true, 'MolaHakkiBitmistir')}</label>

      <label style={{display:"none"}} id="Birhataolustu">{setLocale(true, 'Birhataolustu')}</label>
      <label style={{display:"none"}} id="Boylebirkayitmevcutdegil">{setLocale(true, 'Boylebirkayitmevcutdegil')}</label>
      <label style={{display:"none"}} id="Eksikbilgilervar">{setLocale(true, 'Eksikbilgilervar')}</label>

     
      
  <Modal
      title={setLocale(true, 'MolaSeciniz')}
      open={props?.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <Form
        name="changePasswordForm"
        layout="vertical"
        form={changePwForm}
        onFinish={handleOk}
      >

        <Form.Item
          label={setLocale(true, 'Mola')}
          name="status"
          rules={[
            { required: true, message: "Lütfen Mola seçiniz !" },
          ]}
        >
          <Select
          

            size="large"
            showSearch
            optionFilterProp="children"
          >
            {
              list?.map((mola) => {
                return (
                  <Option key={mola?.ID} value={mola?.ID}>{mola?.MaxAmountOfUser + " - " + mola?.Name}</Option>
                )
              })
            }
          </Select>
        </Form.Item>
      
        <Form.Item style={{ marginTop: "4rem", textAlign: "right" }}>
          <Button type="primary" htmlType="submit" >
          {setLocale(true, 'Baslat')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>

  </div>
  

  )
}

export default StatusModal
