import React, { useState, useRef, useEffect, useContext } from "react";
import { Modal } from "antd";
import baseService from "services/BaseService";
import CounterContext from "contexts/CounterContext";
import IntlMessage from "../IntlMessage";
import Utils from "utils";
import ls from 'localstorage-slim';

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

export const DisplayTimer = ({ time }) => {
  const id = ls.get("id", { decrypt: true })
  const { setstatus } = useContext(CounterContext)
  const { setmolaadet } = useContext(CounterContext)
  const { setmolasure } = useContext(CounterContext)
  const { setTime } = useContext(CounterContext)
  const { setmoladisabled } = useContext(CounterContext)

  const [timerHours, setTimerHours] = useState("0")
  const [timerMinutes, setTimerMinutes] = useState("0")
  const [timerSeconds, setTimerSeconds] = useState("0")
  function success(message) {
    Modal.success({
      content: message,
    });
  }
  function error(message) {
    Modal.error({
      content: message,
    });
  }
  let interval = useRef()
  const startTimer = () => {

    if (time === 0 || time === "0") {
      if (interval?.current) {
        clearInterval(interval.current)
      }
    }
    else {
      let a = 0;
      let idd = "";
      interval.current = setInterval(async () => {

        if (a === 0) {
          let data = await baseService.getPost("api/getagentmola/" + id);
          idd = data.ID;

        }
        a = 1;
        const now = new Date().getTime();
        const distance = time - now//countdownDate - now;
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
        const minutes = Math.floor((distance % (1000 * 60 * 60) / (1000 * 60)));
        const seconds = Math.floor((distance % (1000 * 60) / (1000)));
        if (hours === 0 && minutes === 0 && seconds === 0) {

          let data2 = await baseService.getPost("api/getagentmola/" + id)
          if (data2.ID === idd) {

            let sonuc = 0;
            if (data2.StatusEndDate) {

              var t1 = new Date();
              var t2 = new Date(data2.StatusEndDate);
              var dif = t1.getTime() - t2.getTime();

              var Seconds_from_T1_to_T2 = dif / 1000;
              var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
              if (Seconds_Between_Dates > 1) {
                sonuc = 1;
              }
            }
            if (sonuc === 0) {
              if (data2?.breakAutoEnd === 1) {
                success(document.getElementById(`MolaSurenizDolmustur`).innerHTML)
              } else {
                Modal.success({
                  content: document.getElementById(`MolaSurenizDolmustur`).innerHTML,
                  onOk: () => breakEnd(),
                  okText: "Molayı Bitir",
                  keyboard: false
                });
              }

              if (interval?.current) {
                clearInterval(interval.current)
              }
              setstatus(false)
              let data = await baseService.getPost("api/userbreak/" + id);
              let breaks = await baseService.getPost("api/break");

              let breakReportStatus;
              breaks.forEach(breakItem => {
                breakReportStatus = {
                  ...breakReportStatus,
                  [breakItem.Name.trim().toLowerCase()]: breakItem.NotReport,
                }
              });

              let toplam = 0;
              let totalBreakCount = 0;

              if (data) {
                data?.forEach(element => {
                  if (!breakReportStatus?.[element?.StatusName?.trim().toLowerCase()]) {
                    if (element?.sure > 0) {
                      toplam = element?.sure + toplam;
                      totalBreakCount++
                    }
                  }
                });


                // Hours, minutes and seconds
                var hrs = ~~(toplam / 3600);
                var mins = ~~((toplam % 3600) / 60);
                var secs = ~~toplam % 60;

                // Output like "1:01" or "4:03:59" or "123:03:59"
                const ret = Utils.timeToStringConverter(hrs, mins, secs)

                setmolaadet(":" + totalBreakCount)
                setmolasure(":" + ret)


              }
            }
            else {
              if (interval?.current) {
                clearInterval(interval.current)
              }
            }
          }
          else {
            if (interval?.current) {
              clearInterval(interval.current)
            }
          }

        }
        if (distance < 0) {
          if (interval?.current) {
            clearInterval(interval.current)
          };
        } else {
          setTimerHours(hours)
          setTimerMinutes(minutes)
          setTimerSeconds(seconds)
        }
      }, 1000)
    }

  }

  useEffect(() => {
    startTimer();
    return () => {
      //prevent memory
      setTimerHours("0")
      setTimerMinutes("0")
      setTimerSeconds("0")
    }
  }, [interval, time])

  const breakEnd = async () => {
    let response = await baseService.setPost("api/userbreak/finish/" + id)
    if (response === "Başarılı") {
      success(document.getElementById(`MolaBitmistir`).innerHTML)
      let response2 = await baseService.setPost("api/userbreak/update/" + id)
      if (response2 === "Başarılı") {
        let response = await baseService.setPost("api/queue/pause/" + id + "/false")
        if (response === "Başarılı") {
          setstatus(false)
          let t = new Date();
          t.setMinutes(t.getMinutes() + 40)
          t = new Date(t).getTime();
          setTime(0)
          setmoladisabled(true)

          let data = await baseService.getPost("api/userbreak/" + id);
          let breaks = await baseService.getPost("api/break");

          let breakReportStatus;
          breaks.forEach(breakItem => {
            breakReportStatus = {
              ...breakReportStatus,
              [breakItem.Name.trim().toLowerCase()]: breakItem.NotReport,
            }
          });

          let toplam = 0;
          let totalBreakCount = 0;
          if (data) {
            data?.forEach(element => {
              if (!breakReportStatus?.[element?.StatusName?.trim().toLowerCase()]) {
                if (element?.sure > 0) {
                  toplam = element?.sure + toplam;
                  totalBreakCount++
                }
              }
            });
            var hrs = ~~(toplam / 3600);
            var mins = ~~((toplam % 3600) / 60);
            var secs = ~~toplam % 60;

            // Output like "1:01" or "4:03:59" or "123:03:59"
            const ret = Utils.timeToStringConverter(hrs, mins, secs)

            setmolaadet(":" + totalBreakCount)
            setmolasure(":" + ret)

          }
        }
      } else {
        error(document.getElementById(`Birhataolustu`).innerHTML)
      }
    } else if (response === "Bir hata oluştu.") {
      error(document.getElementById(`Birhataolustu`).innerHTML)
    }
    else if (response === "Böyle bir kayıt mevcut değil.") {
      error(document.getElementById(`Boylebirkayitmevcutdegil`).innerHTML)
    }
    else if (response === "Eksik bilgiler var.") {
      error(document.getElementById(`Eksikbilgilervar`).innerHTML)
    }
    else {
      error(document.getElementById(`Birhataolustu`).innerHTML)
    }
  };
  return (


    <>
      <label style={{ display: "none" }} id="MolaSurenizDolmustur">{setLocale(true, 'MolaSurenizDolmustur')}</label>

      <span>{timerHours >= 10 ? timerHours : '0' + timerHours}</span>&nbsp;:&nbsp;
      <span>{timerMinutes >= 10 ? timerMinutes : '0' + timerMinutes}</span>&nbsp;:&nbsp;
      <span>{timerSeconds >= 10 ? timerSeconds : '0' + timerSeconds}</span>

    </>

  )
}
