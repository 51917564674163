import React, { useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message, Space } from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import baseService from 'services/BaseService';
import ls from "localstorage-slim";

const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const NavSpeaker = () => {
  const [speakerDevices, setSpeakerDevices] = useState([]);
  const [selectedSpeakerDeviceType, setSelectedSpeakerDeviceType] = useState(null);

  useEffect(() => {
    getSpeakerDevices();
  }, []);

  useEffect(() => {
    const storedDevice = ls.get('UsingSpeakerDevice', { decrypt: true });
    const storedDeviceId = ls.get('UsingSpeakerDeviceId', { decrypt: true });

    if (storedDevice && storedDeviceId) {
      const matchingDevice = speakerDevices.find(device => device.deviceId === storedDeviceId);
      if (matchingDevice) {
        startUsingSpeakerDevice(matchingDevice.deviceId);
        setSelectedSpeakerDeviceType(storedDevice);
      }
    }
  }, [speakerDevices]);

  const getSpeakerDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const speakerDevices = devices.filter(device => device.kind === 'audiooutput');
      setSpeakerDevices(speakerDevices);
    } catch (error) {
      console.error('Hoparlör cihazları alınırken bir hata oluştu:', error);
    }
  };

  const startUsingSpeakerDevice = async (deviceId) => {
    try {
      const constraints = { audio: { deviceId: { exact: deviceId } } };
      await navigator.mediaDevices.getUserMedia(constraints);
    } catch (error) {
      console.error('Hoparlör cihazı kullanılırken bir hata oluştu:', error);
    }
  };

  const handleDeviceSelect = (deviceId, deviceType) => {
    startUsingSpeakerDevice(deviceId);
    setSelectedSpeakerDeviceType(deviceType);

    ls.set('UsingSpeakerDeviceId', deviceId, { encrypt: true });

    ls.set('UsingSpeakerDevice', deviceType, { encrypt: true });

    const action = `Telefon hoparlörü ${deviceType ? deviceType : 'default'} olarak değiştirildi.`;

    baseService.createLog(`api/logs/create`, action)
        .then()
        .catch((err) => {
          console.log(err);
        });

    message.success(`${action}`);
  };

  const items = [
    {
      label: (
          <Menu>
            {speakerDevices.length > 0 && speakerDevices.map(device => (
                <Menu.Item key={device.deviceId} onClick={() => handleDeviceSelect(device.deviceId, device.label)}>
                  {device.label ? device.label : setLocale(true, 'phone.settings.default') }
                </Menu.Item>
            ))}
          </Menu>
      ),
      key: '0',
    },
  ];

  return (
      <div>
        {speakerDevices.length > 0 ? (
            <Dropdown menu={{ items }} trigger={['click']}>
              <Space>
                {selectedSpeakerDeviceType ? selectedSpeakerDeviceType : setLocale(true, 'phone.settings.default')}
                <DownOutlined />
              </Space>
            </Dropdown>
        ) : (
            setLocale(true, 'phone.settings.default')
        )}
      </div>
  );
};

export default NavSpeaker;