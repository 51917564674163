import React, { useState, useEffect, useContext } from "react";
import { Table, Modal, Space, Switch } from "antd";
import utils from "utils";
import baseService from 'services/BaseService'
import IntlMessage from "../../util-components/IntlMessage";
import QueueRefresherContext from "contexts/QueueRefresherContext";
import ls from 'localstorage-slim';

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

function KuyrukSecimiModal(props) {
  const [list, setList] = useState([]);
  const [refreshlist, setrefreshlist] = useState(true);
  const { setQueueRefresher } = useContext(QueueRefresherContext)

  useEffect(() => {
    (async function () {
      let id = ls.get("id", { decrypt: true });
      let data = await baseService.getPost("api/queue/" + id);
      let data2 = await baseService.getPost("api/queues_config");
      if (data?.length > 0 && data2?.length > 0) {
        data?.forEach(element => {
          data2?.forEach(element2 => {
            if (element?.extension === element2?.extension) {
              element.queue = element2?.descr
            }
          });

        });
      }
      setList(data);
    })();
  }, [refreshlist]);

  const queueaktif = (row) => {

    (async function () {
      let id = ls.get("id", { decrypt: true });
      if (row?.event) {
        let data = {
          Queue: [row?.extension],
          Interface: row?.user,
          id: id,
          res: true,
        }
        await baseService.setPost("api/queue/remove", data);
        setrefreshlist(!refreshlist)
      }
      else {
        let data = {
          Queue: [row?.extension],
          Interface: row?.user,
          id: id,
          res: true,
          penalty: row?.penalty,
        }
        await baseService.setPost("api/queue/add", data);
        setrefreshlist(!refreshlist)
      }
      setQueueRefresher(prev => !prev)
    })();

    const action = 'Kuyruk seçim işlemi yapıldı.';

    baseService.createLog(`api/logs/create`, action).then().catch((err) => {console.log(err)});
  }
  const tableColumns = [
    {
      title: setLocale(true, 'rapor.Kuyruk'),
      dataIndex: "queue",
      sorter: (a, b) => utils.antdTableSorter(a, b, "queue"),
    },
    {
      title: setLocale(true, 'rapor.DahiliNumara'),
      dataIndex: "user",
      sorter: (a, b) => utils.antdTableSorter(a, b, "user"),
    },
    {
      dataIndex: 'event',
      render: (event, elm) => {
        return (
          <Space wrap>
            <Switch
              checked={event}
              checkedChildren={setLocale(true, 'Login')}
              unCheckedChildren={setLocale(true, 'Logout')}
              onChange={() => {
                queueaktif(elm)
              }}
            />
          </Space>
        )
      },
    },
  ];
  const handleCancel = e => {
    props.setkuyrukvisible(false);

    const action = 'Kuyruk seçimi kapatıldı.';

    baseService.createLog(`api/logs/create`, action).then().catch((err) => {console.log(err)});
  };

  return (
    <Modal
      title={setLocale(true, 'KuyrukSecimi')}
      open={props?.kuyrukvisible}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={false}
    >
      <Table pagination={{ defaultPageSize: 20 }}
        columns={tableColumns}
        dataSource={list}
        rowKey="extension"

      />
    </Modal>
  )
}

export default KuyrukSecimiModal
