import React from 'react'
import AuthViews from 'views/auth-views'

export const AuthLayout = () => {
	return (
		<div className="auth-container">
			<AuthViews />
		</div>
	)
}


export default AuthLayout