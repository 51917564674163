
import {
  TOGGLE_COLLAPSED_NAV,
  CHANGE_LOCALE,
  TOGGLE_MOBILE_NAV,
} from '../constants/Theme';

export function toggleCollapsedNav(navCollapsed) {
  return {
    type: TOGGLE_COLLAPSED_NAV,
    navCollapsed
  };
}

export function onLocaleChange(locale) {
 
  return {
    type: CHANGE_LOCALE,
    locale
  };
}

export function onMobileNavToggle(mobileNav) {
  return {
    type: TOGGLE_MOBILE_NAV,
    mobileNav
  };
}
